import React from 'react';
import Globals from '../../contexts/Globals';
import I18nLib from '../../lib/I18nLib';
import { Link } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';

function FootballHomeHeader(){

	let globals = Globals.useContainer();
	let i18n = I18nLib.init(globals);
	const isPhone = useMediaQuery({ query: globals.phoneMediaQuery });

	let subtitleImage = `${globals.config.baseImageUrl}/website/blackout.rugby/2023/home-title-${isPhone ? 'tall' : 'wide'}.png`;

	return (
		<div>
			<div>
				<img src={`${globals.config.baseImageUrl}/website/blackout.rugby/2023/football-logo-short.png`} alt="Blackout Football" id="home-football-logo" />
			</div>
			<div>
				<img src={subtitleImage} alt={i18n('home.title', 'The next football manager')} className="home-subtitle" />
			</div>
			<div>
				{/*<a href="https://store.steampowered.com/app/1157960/Blackout_Rugby_Manager/" target="_blank" rel="noopener noreferrer" className="home-header-button download">{i18n('buttons.download', 'Download Now')}</a>*/}
				<a href="https://store.steampowered.com/app/2187050" target="_blank" rel="noopener noreferrer" className="wishlist-button">
					<img src={`${globals.config.baseImageUrl}/website/blackout.rugby/2024/wishlist-on-steam-button.png`} alt="Wishlist on Steam" />
				</a>
				<Link to="/signup" className="home-header-button signup">
					{i18n('buttons.pre-signup', 'Pre-Signup')}
				</Link>
			</div>
			<div className="home-header-text">
				{i18n('home.help-us-test', 'Help us test!')} <a href={globals.sportConfig.discordUrl} target="_blank" rel="noopener noreferrer"><span className="discord-text">{i18n('home.ask-in-discord', 'Ask in Discord')}<img src={`${globals.config.baseImageUrl}/signup-website/social-icon-discord.png`} className="social-icon discord inline-icon" alt="Discord" style={{height:'25px'}} /></span></a>
			</div>
			<div className="home-text">
				{/*i18n('home.availability', 'Available now on Steam, iOS and Android!')*/}
				{i18n('home.coming-soon', 'Coming soon to Steam, iOS and Android!')}
			</div>
			<div className="home-store-buttons">
				<span className="store-button"><a href="https://store.steampowered.com/app/2187050" target="_blank" rel="noopener noreferrer"><img src={`${globals.config.baseImageUrl}/signup-website/download-steam.png`} className="platform-image" alt="Download on Steam" /></a></span>
				<span className="store-button">
					<a href="https://apps.apple.com/app/blackout-football-manager/id6443909470" target="_blank" rel="noopener noreferrer"><img src={`${globals.config.baseImageUrl}/signup-website/download-apple.png`} className="platform-image" alt="Download on iOS" /></a>
				</span>
				<span className="store-button last">
					<a href="https://play.google.com/store/apps/details?id=football.blackout.android" target="_blank" rel="noopener noreferrer"><img src={`${globals.config.baseImageUrl}/signup-website/download-google.png`} className="platform-image" alt="Download on Android" /></a>
				</span>
			</div>
		</div>
	);

}

export default FootballHomeHeader;